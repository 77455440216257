@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-mail {
  display: flex;
  width: 100%;
  height: 38px;
  border-bottom: 1px solid rgba(128, 128, 128, 0.233);
  justify-content: space-between;

  .single-mail-checkbox-star-group {
    @include flex-center;
    height: 100%;
    width: 5%;
    cursor: pointer;
    .single-mail-checkbox-star {
      @include flex-center;
      width: 30px;
      height: 100%;
    }
    * {
      font-size: 1.2rem;
      cursor: pointer;
    }
  }
  .link {
    text-decoration: none !important;
    color: black;
    width: 80%;
    height: 90%;
    display: flex;
    justify-content: start;

    .single-mail-sender {
      @include flex-center;
      justify-content: start;
      height: 100%;
      width: 17%;
      font-size: 0.9rem;
      font-weight: bolder;
    }
    .single-mail-title-discription {
      width: 83%;
      display: flex;
      .single-mail-title {
        display: flex;
        align-items: center;
        height: 100%;
        font-size: 0.9rem;
        font-weight: bolder;
        white-space: nowrap !important;
      }
      .single-mail-discription {
        height: 100%;
        font-size: 0.9rem;
        white-space: nowrap !important;
        overflow: hidden;
        text-overflow: ellipsis !important;
        padding-top: 5px;
      }
    }
    .ReadMail {
      font-weight: normal !important;
    }
  }
  .single-mail-actions-btns {
    width: 10%;
    height: 100%;
    @include flex-center;
    justify-content: space-evenly;
    * {
      cursor: pointer;
    }
   
  }
  .single-mail-send-time {
    @include flex-center;
    justify-content: start;
    height: 100%;
    width: 5%;
    font-weight: bolder;
  }
  .ReadMail {
    font-weight: normal !important;
  }
}

.single-mail:hover {
  box-shadow: 0px 0px 4px gray;
}
