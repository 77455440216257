* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AllMails {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  width: 83%;
  height: 90vh;

  .AllMails-header-pagination {
    display: flex;
    width: 100%;
    height: 60px;
    border-bottom: 1px solid #77777731;
    justify-content: space-between;

    .AllMails-header-left-group {
      width: 150px;
      display: flex;
      color: #777777;
      * {
        cursor: pointer;
      }
      .AllMails-Checkall-checkbox {
        width: 35.5%;
        @include flex-center;
      }
      .AllMails-refresh {
        width: 35.5%;
        @include flex-center;
      }
      .AllMails-header-menu {
        width: 35.5%;
        @include flex-center;
        :hover {
          background-color: #f1f3f4;
          border-radius: 50%;
        }
      }
    }
    .AllMails-header-right-group {
      width: 190px;
      display: flex;
      color: #777777;

      .AllMails-header-pages {
        width: 55%;
        @include flex-center;
        font-size: 12px;
      }
      .AllMails-header-pagination-icons {
        width: 22.5%;
        @include flex-center;
        cursor: pointer;
        :hover {
          background-color: #f1f3f4;
          border-radius: 50%;
        }
        * {
          font-size: 15px;
        }
      }
    }
  }

  .AllMails-filters {
    display: flex;
    align-items: center;
    width: 100%;
    height: 65px;
    border-bottom: 1px solid rgba(94, 88, 88, 0.178);
    .AllMails-filters-item {
      width: 255px;
      height: 100%;
      display: flex;
      color: #615d5d;
      display: flex;
      align-items: center;
      cursor: pointer;
      span {
        margin: 10px 10px;
        * {
          font-size: 1.2rem;
        }
      }
      p {
        font-size: 1rem;
      }
    }
  }
  .AllMails-scroll {
    height: 100%;
    width: 100%;
    overflow: scroll;
    padding-left: 1px; 
  }
  ::-webkit-scrollbar {
    width: 5px;
    display: block;
  }

  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
    outline: 1px solid slategrey;
  }
  .AllMails-single-mail:hover {
    box-shadow: 1px 1px 1px rgba(131, 127, 127, 0.589),
      0px 0px 3px rgba(100, 97, 97, 0.719);
  }
}



.primary {
  border-bottom: 3px solid #d73025; 
  border-radius: 0 0 3px 3px;
  * {
    color: #d93025;
  }
}
.social {
  border-bottom: 3px solid #1a73e8;
  border-radius: 0 0 3px 3px;
  * {
    color: #1a73e8;
  }
}
.promotions {
  border-bottom: 3px solid #188038;
  border-radius: 0 0 3px 3px;
  * {
    color: #188038;
  }
}
