* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nav {
  display: flex;
  align-items: center;
  width: 100%;
  height: 63px;
  border-bottom: 1px solid rgb(233, 225, 225);
  .menu {
    @include flex-center;
    width: 4rem;
    height: 100%;
    cursor: pointer;
    color: rgb(95, 99, 104); 

  }
  .nav-logo {
    @include flex-center;
    width: 7.5%;
    height: 100%;
    img {
      width: 100%;
      height: 70%;
    }
    @media (max-width: 500px) {
      display: none;
    }
  }
  .nav-search {
    display: flex;
    align-items: center;
    width: 77%;
    height: 100%;

    .search {
      @include flex-center;
      background-color: rgb(241, 243, 244);
      border-radius: 10px;
      margin-left: 5rem;
      width: 45.3rem;
      height: 80%;
      @media (max-width: 1050px) {
        margin-left: 1rem;
      }
      .search-icon {
        @include flex-center;
        cursor: pointer;
        width: 7%;
        color: rgb(95, 99, 104); 
        height: 100%;
        @media (max-width: 500px) {
          display: none;
        }
      }
      .search-input {
        @include flex-center;
        width: 86%;
        height: 100%;
        border: none;
        background-color: rgb(241, 243, 244);
      }
      :focus {
        outline: none;
      }
      .search-filter {
        @include flex-center;
        cursor: pointer;
        width: 7%;
        height: 100%;
        color: rgb(95, 99, 104);
        @media (max-width: 500px) {
          display: none;
        }
      }
    }
  }
  .nav-right-icons {
    @include flex-center;
    cursor: pointer;
    width: 2.7rem;
    height: 100%;
    color: rgb(95, 99, 104);
  }

  .nav-google-account {
    @include flex-center;
    cursor: pointer;
    width: 2.4rem;
    height: 60%;
    border-radius: 50%;
    background-color: green;
    color: white;
    @media (max-width: 500px) {
      display: none;
    }
  }
}
